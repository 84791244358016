import React from 'react'
import { graphql } from 'gatsby'

import { providersPageSelector } from '@data'
import {
  NavBar,
  Hero,
  Perks,
  Works,
  Data,
  Card,
  Ready,
  Footer,
  SEO,
} from '@components'

import '@styles/providers.scss'

const Providers = (props) => {

  const { data: prismic = {} } = { ...props }
  const data = providersPageSelector(prismic)

  const { perks = {}, perksSectionItems = [], card = {}, cardSectionItems = [] } = { ...data }
  const { title: perksSectionTitle = '' } = {  ...perks }
  const { title: cardSectionTitle = '', description: cardSectionDescription = '' } = {  ...card }

  return (
    <section>
      <SEO title={'Providers'} />
      <NavBar data={data} />
      <Hero section={'providers'} data={data} />
      <section className={'big-blue-providers'}>
        <section className={'providers'}>
          <div className={'providers__container'}>
            <h2
              className={'providers__container-title'}
              data-sal={'fade'}
              data-sal-delay={'50'}
              data-sal-easing={'ease'}
              data-sal-duration={'800'}
            >
              {perksSectionTitle}
            </h2>
            <section className={'perks-providers'}>
              {(perksSectionItems || []).map((perksSectionItem, index) => {
                return (
                  <Perks
                    key={index}
                    perksSectionItem={perksSectionItem}
                    index={index}
                  />
                )
              })}
            </section>
          </div>
        </section>
        <section className={'how-it-works-bg'}>
          <section className={'how-it-works'}>
            <Works data={data} />
          </section>
          <section className={'look-mobile-providers'}>
            <Data
              data={data}
              section={'providers'}
              showButton
            />
          </section>
        </section>
        <section className={'card'}>
          <div className={'card__container'}>
            <div className={'card__container_head'}>
              <h2
                data-sal={'fade'}
                data-sal-delay={'50'}
                data-sal-easing={'ease'}
                data-sal-duration={'800'}
              >
                {cardSectionTitle}
              </h2>
              <p
                data-sal={'fade'}
                data-sal-delay={'100'}
                data-sal-easing={'ease'}
                data-sal-duration={'800'}
              >
                {cardSectionDescription}
              </p>
            </div>
            <div className={'card__container_body'}>
              {(cardSectionItems || []).map((cardSectionItem, index) => {
                return (
                  <Card cardSectionItem={cardSectionItem} index={index} key={index} />
                )
              })}
            </div>
          </div>
        </section>
        <section className={'ready'}>
          <Ready data={data} />
        </section>
      </section>
      <Footer data={data} />
    </section>
  )
}

export const query = graphql`
  query ProvidersPageQuery($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    prismicProvidersPage(lang: { eq: $lang }) {
      data {
        body {
          primary {
            navbar_section_logo {
              url
            }
            navbar_section_logo_mobile {
              url
            }
            navbar_section_cta_label_home {
              text
            }
            navbar_section_cta_link_home {
              text
            }
            navbar_section_cta_label_providers {
              text
            }
            navbar_section_cta_link_providers {
              text
            }
            navbar_section_cta_label_companies {
              text
            }
            navbar_section_cta_link_companies {
              text
            }
            navbar_section_cta_label_demo {
              text
            }
            navbar_section_cta_link_demo {
              url
            }
            navbar_section_cta_label_login {
              text
            }
            navbar_section_cta_link_login {
              url
            }
            navbar_section_cta_label_switcher {
              text
            }
            navbar_section_cta_link_switcher {
              text
            }
            hero_cta_label {
              text
            }
            hero_cta_link {
              url
            }
            hero_image {
              url
            }
            hero_paragraph {
              text
            }
            hero_title {
              text
            }
            perks_section_title {
              text
            }
            works_section_title {
              text
            }
            works_section_paragraph {
              text
            }
            data_section_title {
              text
            }
            data_section_paragraph {
              text
            }
            data_section_cta_label {
              text
            }
            data_section_cta_link {
              url
            }
            data_section_image {
              url
            }
            card_section_title1 {
              text
            }
            card_section_paragraph {
              text
            }
            ready_section_title {
              text
            }
            ready_section_cta_label {
              text
            }
            ready_section_paragraph {
              text
            }
            ready_section_cta_link {
              url
            }
            footer_section_logo {
              url
            }
            footer_section_cta_label_about {
              text
            }
            footer_section_cta_label_with_us {
              text
            }
            footer_section_cta_label_press {
              text
            }
            footer_section_cta_link_press {
              text
            }
            footer_section_cta_label_contact {
              text
            }
            footer_section_logo_facebook {
              url
            }
            footer_section_cta_link_facebook {
              url
            }
            footer_section_logo_instagram {
              url
            }
            footer_section_cta_link_instagram {
              url
            }
            footer_section_logo_linkedin {
              url
            }
            footer_section_cta_link_linkedin {
              url
            }
            footer_section_copyright {
              text
            }
            footer_section_cta_label_privacy {
              text
            }
            footer_section_cta_label_terms {
              text
            }
          }
          items {
            perk_image {
              url
            }
            perk_title {
              text
            }
            perk_paragraph {
              text
            }
            works_image {
              url
            }
            works_title {
              text
            }
            works_paragraph {
              text
            }
            card_title {
              text
            }
            card_value {
              text
            }
            card_description {
              text
            }
          }
        }
      }
    }
  }
`
export default Providers
